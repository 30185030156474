import styled from "styled-components";

export const Header = styled.div`
  display: flex;
  gap: 16px;
  align-items: baseline;
`;

export const Card = styled.div`
  padding: 32px;
  border-radius: 8px;
  border: 1px solid white;
  display: flex;
  gap: 32px;
  align-items: center;

  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

export const Container = styled.div`
  margin: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
