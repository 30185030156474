import styled from "styled-components";

export const ImageContainer = styled.div`
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  box-sizing: content-box;
  max-height: 400px;
`;
