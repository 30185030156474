import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import englishTranslations from "./assets/locales/englishTranslations";
import serbianTranslations from "./assets/locales/serbianTranslations";

const resources = {
  en: {
    translation: englishTranslations,
  },
  sr: {
    translation: serbianTranslations,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
