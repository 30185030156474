import location from "../../assets/img/locationTrg.png";
import { HaircutImage } from "../../App.styled";
import { Container, WhereToFindUs } from "./AboutUs.styled";
import { useTranslation } from "react-i18next";

const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <Container id="about-section">
      <h1>{t("aboutUs")}</h1>
      {t("aboutUsDescription")}
      <div>
        <h3>{t("whereToFindUs")}</h3>
        <WhereToFindUs>
          <HaircutImage alt="location" src={location} />
          <p>{t("whereToFindUsDescription")}</p>
        </WhereToFindUs>
      </div>
    </Container>
  );
};

export default AboutUs;
