import { Container, FooterItem, MailIcon, PhoneIcon } from "./Footer.styled";
import { Instagram, Location } from "../../assets/svg";

const Footer = () => {
  return (
    <Container>
      <FooterItem
        target="_blank"
        href="https://www.google.com/maps/place/Kara%C4%91or%C4%91ev+Trg+17,+Zrenjanin/data=!4m2!3m1!1s0x475adb3e31f17d75:0xbe79186d785e031a?sa=X&ved=1t:242&ictx=111"
      >
        <Location />
        Karadjordjev Trg 17
      </FooterItem>
      <FooterItem
        href="https://www.instagram.com/radjenovic_frizerskiatelje/"
        target="_blank"
        rel="noreferrer"
      >
        <Instagram />
        <span>radjenovic_atelje</span>
      </FooterItem>
      <FooterItem href="mailto:radjenovciatelje@gmail.com?cc=&subject=&body=Hello,%20Radjenovic!%20I%20would%20like%20to%20get%20in%20touch%20with%20you.%0A%0ABest%20regards,%0A[Your%20Name]">
        <MailIcon />
        <span>radjenovicatelje@gmail.com</span>
      </FooterItem>
      <FooterItem href="tel:+381606231023">
        <PhoneIcon />
        +381606231023
      </FooterItem>
    </Container>
  );
};

export default Footer;
