import { Barber1, Barber2, CreamSkin, Scissors } from "../../assets/svg";
import haircut from "../../assets/img/haircut8.png";
import haircut3 from "../../assets/img/haircut3.png";
import { Card, Container, Header } from "./Services.styled";
import { HaircutImage } from "../../App.styled";
import { useTranslation } from "react-i18next";

const Services = () => {
  const { t } = useTranslation();

  return (
    <Container id="services-section">
      <Header>
        <h1>{t("services")}</h1>
        <Scissors />
        <Barber1 />
        <Barber2 />
        <CreamSkin />
      </Header>
      <Card>
        <HaircutImage src={haircut} alt="haircut" />
        <div>
          <h4>{t("haircutTitle")}</h4>
          <p>{t("haircutDescription")} </p>
        </div>
      </Card>
      <Card>
        <HaircutImage src={haircut3} alt="haircut" />
        <div>
          <h4>{t("beardTitle")}</h4>
          <p>{t("beardDescription")} </p>
        </div>
      </Card>
    </Container>
  );
};

export default Services;
