import { MouseEventHandler } from "react";
import {
  Container,
  LanguagePicker,
  Logo,
  MenuItem,
  SubMenu,
} from "./Header.styled";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { t, i18n } = useTranslation();

  const scrollToSection: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();

    const sectionId = e.currentTarget.getAttribute("href")?.slice(1);

    const element = sectionId ? document.getElementById(sectionId) : undefined;
    if (!element) {
      return;
    }

    const y = element.getBoundingClientRect().top + window.pageYOffset - 250; // Scroll fix // 100 za large screen

    window.scrollTo({
      behavior: "smooth",
      top: y,
    });
  };

  return (
    <Container>
      <SubMenu>
        <Logo alt="logo" />
        <a href="#home-section" onClick={scrollToSection}>
          <MenuItem>{t("home")}</MenuItem>
        </a>
        <a href="#services-section" onClick={scrollToSection}>
          <MenuItem>{t("services")}</MenuItem>
        </a>
        <a href="#about-section" onClick={scrollToSection}>
          <MenuItem>{t("about")}</MenuItem>
        </a>
      </SubMenu>
      <SubMenu>
        <LanguagePicker onClick={() => i18n.changeLanguage("sr")}>
          <img src="https://flagsapi.com/RS/flat/32.png" alt="SR" />
          {t("sr")}
        </LanguagePicker>

        <LanguagePicker onClick={() => i18n.changeLanguage("en")}>
          <img src="https://flagsapi.com/US/shiny/32.png" alt="EN" />
          {t("en")}
        </LanguagePicker>
      </SubMenu>
    </Container>
  );
};

export default Header;
