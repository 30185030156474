import styled from "styled-components";

export const BlurredDiv = styled.div`
  height: 16px;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10000;
  background-color: rgba(20, 20, 20, 0.6);
  filter: blur(1px);
`;

export const HaircutImage = styled.img`
  max-width: 196px;
  max-height: 240px;
  width: auto;
  height: auto;
  float: left;
  border: 1px solid white;
  border-radius: 8px;
`;

export const Home = styled.main`
  margin: 32px;
  padding: 32px;
  border-radius: 8px;
  border: 1px solid white;
`;
