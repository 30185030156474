import styled from "styled-components";
import logo from "../../assets/img/logo.png";

export const Container = styled.header`
  margin: 32px 16px;
  margin-top: 0;
  padding: 16px 32px;
  border-radius: 8px;
  border: 1px solid white;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 16px;
  z-index: 100;
  background-color: rgb(20, 20, 20);

  @media (max-width: 480px) {
    justify-content: center;
    flex-direction: column-reverse;
    gap: 10px;
    margin-left: 8px;
    margin-right: 8px;
  }
`;

export const SubMenu = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;

  /* Stavi logo da pada levo od teksta na scroll i position absolute */
  @media (max-width: 480px) {
    &:first-child {
      flex-direction: column;
      gap: 2px;
    }
  }
`;

export const MenuItem = styled.div`
  padding: 8px;
  min-width: fit-content;
  height: fit-content;
  font-size: 18px;
  box-sizing: content-box;
  transition: all 0.5s ease;
  border-bottom: 0px solid transparent;
  cursor: pointer;

  &:hover {
    border-bottom: 2px solid white;
  }

  @media (max-width: 480px) {
    padding: 0;
  }
`;

export const Logo = styled.img.attrs({ src: logo })`
  width: 64px;
  border-radius: 4px;
`;

export const LanguagePicker = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;
