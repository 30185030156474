import ReactMultiCarousel from "react-multi-carousel";
import haircut from "../../assets/img/haircut.png";
import haircut2 from "../../assets/img/haircut2.png";
import haircut3 from "../../assets/img/haircut3.png";
import haircut4 from "../../assets/img/haircut4.png";
import haircut5 from "../../assets/img/haircut5.png";
import haircut6 from "../../assets/img/haircut6.png";
import haircut7 from "../../assets/img/haircut7.png";
import haircut8 from "../../assets/img/haircut8.png";
import haircut9 from "../../assets/img/haircut9.png";
import { HaircutImage } from "../../App.styled";
import { ImageContainer } from "./Carousel.styled";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 3,
  },
  tablet2: {
    breakpoint: { max: 768, min: 576 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 576, min: 0 },
    items: 1,
  },
};

const Carousel = () => {
  return (
    <ReactMultiCarousel
      swipeable={true}
      draggable={false}
      responsive={responsive}
      infinite={true}
      keyBoardControl={true}
    >
      <ImageContainer>
        <HaircutImage src={haircut7} alt="haircutImg" />
      </ImageContainer>
      <ImageContainer>
        <HaircutImage src={haircut4} alt="haircutImg" />
      </ImageContainer>
      <ImageContainer>
        <HaircutImage src={haircut3} alt="haircutImg" />
      </ImageContainer>
      <ImageContainer>
        <HaircutImage src={haircut8} alt="haircutImg" />
      </ImageContainer>
      <ImageContainer>
        <HaircutImage src={haircut5} alt="haircutImg" />
      </ImageContainer>

      <ImageContainer>
        <HaircutImage src={haircut6} alt="haircutImg" />
      </ImageContainer>
      <ImageContainer>
        <HaircutImage src={haircut9} alt="haircutImg" />
      </ImageContainer>
      <ImageContainer>
        <HaircutImage src={haircut2} alt="haircutImg" />
      </ImageContainer>
      <ImageContainer>
        <HaircutImage src={haircut} alt="haircutImg" />
      </ImageContainer>
    </ReactMultiCarousel>
  );
};

export default Carousel;
