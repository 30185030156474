import styled from "styled-components";

export const Container = styled.div`
  margin: 32px;
  padding: 32px;
  border-radius: 8px;
  border: 1px solid white;
`;

export const WhereToFindUs = styled.div`
  padding: 32px;
  display: flex;
  gap: 32px;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
