const englishTranslations = {
  description:
    "Welcome to Radjenovic Hairdressing Studio, your oasis for excellence in the art of hairdressing. In our studio, every detail is meticulously crafted to ensure your visit is an unforgettable experience. Our passion for hair beauty shines through in every style we create. Allow our experts to guide you through a wide range of services, including modern haircuts and impeccable beard styling. At Radjenovic Hairdressing Studio, we offer more than just a haircut - we provide you with a unique opportunity to unwind, relax, and feel beautiful.",
  aboutOurWork: "Radjenovic hairdressing studio",
  home: "Home",
  services: "Services",
  about: "About",
  aboutUsDescription:
    "Welcome to Radjenovic Hairdressing Studio, where passion for the art of hairdressing meets modern elegance. Founded with passion and dedication in 2021, our studio is where tradition meets innovation. Whether you're coming to refresh your look or indulge in a luxurious treatment, at Radjenovic Hairdressing Studio you will always find exceptional service in a sophisticated atmosphere. Come and discover why we are more than just a hair salon - we are a place where beauty shines anew.",
  sr: "RS",
  en: "EN",
  aboutUs: "About us",
  whereToFindUs: "Where to find us?",
  whereToFindUsDescription:
    "You can find Radjenovic Hairdressing Studio conveniently located at Karadjordjev Trg 17, Zrenjanin. Nestled in a vibrant neighborhood, our studio offers a stylish and welcoming atmosphere where you can indulge in top-notch hairdressing services. Whether you're a local resident or visiting the area, we invite you to experience our commitment to excellence in every haircut, and beard styling session. Join us at Karadjordjev Trg 17 and discover a place where beauty meets artistry.",
  haircutTitle: "Haircutting Service",
  haircutDescription:
    "Indulge in personalized haircutting services crafted exclusively by our dedicated owner. Whether you're seeking a modern style update, a timeless classic, or a meticulous trim, our owner-stylist delivers exceptional craftsmanship tailored to your preferences. Using premium techniques and quality products, we ensure your hair receives the utmost care and attention. Enjoy a relaxed atmosphere and leave with a hairstyle that perfectly suits your individuality.",
  beardTitle: "Beard Trimming Service",
  beardDescription:
    "Elevate your grooming routine with expert beard trimming by our dedicated owner. Specializing in precise beard shaping and meticulous detailing, our owner-barber ensures your beard maintains a sharp, well-groomed appearance. Whether you prefer a neat trim, stylish fade, or intricate designs, we cater to your unique preferences with skill and care. Using top-quality products, we guarantee a refined look that enhances your facial features and leaves you feeling confident and polished.",
};

export default englishTranslations;
