import styled from "styled-components";
import { Mail, Phone } from "../../assets/svg";

export const Container = styled.footer`
  padding-bottom: 24px;
  display: flex;
  justify-content: center;
  gap: 16px;
  align-items: center;

  @media (max-width: 920px) {
    flex-direction: column;
  }
`;

export const MailIcon = styled(Mail)`
  width: 24px;
  height: 24px;
`;

export const PhoneIcon = styled(Phone)`
  width: 24px;
  height: 24px;
`;

export const FooterItem = styled.a`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 24px;
`;
